import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../config";

const useGetAllObejkte = () => {
	const [data, setData] = useState(null); // Состояние для хранения данных
	const [error, setError] = useState(null); // Состояние для хранения ошибок
	const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки

	useEffect(() => {
		// Функция для выполнения запроса
		const fetchAssignments = async () => {
			try {
				// URL вашего скрипта
				const response = await axios.get(`${API_URL}/get_initial.php`);

				// Предполагаем, что сервер возвращает объект с ключом 'unassignments', который содержит JSON
				const unassignmentsJson = response.data.unassignments;

				// Парсим JSON из строки
				let unassignments = JSON.parse(unassignmentsJson);
				unassignments = unassignments.sort((a, b) => {
					if (a.city < b.city) {
						return -1;
					}
					if (a.city > b.city) {
						return 1;
					}
					return 0;
				});

				// Устанавливаем распарсенные данные в состояние
				setData(unassignments);
			} catch (err) {
				setError(err.message); // Устанавливаем сообщение об ошибке
			} finally {
				setLoading(false); // Завершаем загрузку
			}
		};

		fetchAssignments();
	}, []); // Запуск эффекта только при монтировании компонента

	return [data, setData, error, loading];
};

export default useGetAllObejkte;

import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../config";

const useGetAllAddressesAPI = week => {
	const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoaded(false);
			setError(null);
			try {
				// Обращаемся к API для получения данных
				const response = await axios.get(
					`${API_URL}/get_weekly_unassignments.php`,
					{
						params: {
							week: week,
						},
					}
				);
				const data = response.data;

				if (response.status === 200 && data.length) {
					setData(JSON.parse(data[0].unassignments));
				} else {
					setError("Für diese Woche wurden keine Objekte gefunden");
				}
			} catch (err) {
				setError("Etwas ist schief gelaufen");
			} finally {
				setIsLoaded(true);
			}
		};

		if (week) {
			fetchData();
		}
	}, [week]); // Зависимость от номера недели

	return { data, isLoaded, error };
};

export default useGetAllAddressesAPI;

// FormComponent.js
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Button, Select, MenuItem } from "@mui/material";
import { tokens, useMode } from "../../theme";
import useAddObjekt from "../../api/useAddObjekt";
import getWeekNumber from "../../utils/getWeekNumber";
import getFBWeekNumber from "../../utils/getFBWeekNumber";

const Form = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [theme, colorMode] = useMode();
	const colors = tokens(theme.palette.mode);

	const [city, setCity] = useState("witten");

	const [createNewObject, isLoaded, error] = useAddObjekt();

	const handleChange = event => {
		setCity(event.target.value);
	};

	const onSubmit = async data => {
		try {
			// Здесь можно выполнить отправку данных на сервер
			data.city = city;
			data.id = Date.now();
			data.week = getFBWeekNumber();
			createNewObject(data);
			reset(); // Сбрасываем состояние формы после успешной отправки
		} catch (error) {
			console.error("Error submitting form data:", error);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="addObjekt__form">
			<div className="addObjekt__group">
				<Controller
					name="address"
					control={control}
					defaultValue=""
					rules={{ required: "Die Adresse des Objektes ist leer" }}
					render={({ field }) => (
						<TextField
							{...field}
							label="Die Adresse"
							className="addObjekt__input"
							variant="filled"
							sx={{
								"& label.Mui-focused": {
									color: "white", // цвет метки при фокусе
								},
							}}
							error={!!errors.address}
							helperText={errors.address ? errors.address.message : ""}
						/>
					)}
				/>
				<Controller
					name="rhythm"
					control={control}
					defaultValue=""
					rules={{ required: "Der Rhytmus ist leer" }}
					render={({ field }) => (
						<TextField
							{...field}
							label="Der Rhytmus"
							variant="filled"
							sx={{
								"& label.Mui-focused": {
									color: "white", // цвет метки при фокусе
								},
							}}
							error={!!errors.rhytm}
							helperText={errors.rhytm ? errors.rhytm.message : ""}
						/>
					)}
				/>
				<Controller
					name="keyObjekte"
					control={control}
					defaultValue=""
					rules={{ required: "Schlüsselhaken ist leer" }}
					render={({ field }) => (
						<TextField
							{...field}
							label="Der Schlüsselhaken"
							variant="filled"
							sx={{
								"& label.Mui-focused": {
									color: "white", // цвет метки при фокусе
								},
							}}
							error={!!errors.keyObjekt}
							helperText={errors.keyObjekt ? errors.keyObjekt.message : ""}
						/>
					)}
				/>

				<Select
					labelId="city-select-label"
					id="city-select"
					value={city}
					label="Select City"
					defaultValue={city}
					onChange={handleChange}
				>
					<MenuItem value="witten">Witten</MenuItem>
					<MenuItem value="witten-annen">Witten Annen</MenuItem>
					<MenuItem value="witten-rüdinghausen">Witten Rüdinghausen</MenuItem>
					<MenuItem value="witten-stockum">Witten Stockum</MenuItem>
					<MenuItem value="witten-bommern">Witten Bommern</MenuItem>
					<MenuItem value="witten-heven">Witten Heven</MenuItem>
					<MenuItem value="witten-herbede">Witten Herbede</MenuItem>
					<MenuItem value="dortmund">Dortmund</MenuItem>
					<MenuItem value="bochum">Bochum</MenuItem>
					<MenuItem value="essen">Essen</MenuItem>
					<MenuItem value="hattingen">Hattingen</MenuItem>
					<MenuItem value="wetter">Wetter</MenuItem>
					<MenuItem value="herdecke">Herdecke</MenuItem>
					<MenuItem value="recklinghausen">Recklinghausen</MenuItem>
					<MenuItem value="herne">Herne</MenuItem>
					<MenuItem value="menden">Menden</MenuItem>
					<MenuItem value="gelsenkirchen">Gelsenkirchen</MenuItem>
					<MenuItem value="hagen">Hagen</MenuItem>
					<MenuItem value="ennepetal">Ennepetal</MenuItem>
				</Select>
			</div>

			<Button
				type="submit"
				color="success"
				variant={"contained"}
				className="addObjekt__submit"
				sx={{ marginTop: "30px" }}
			>
				Hinzufügen
			</Button>
		</form>
	);
};

export default Form;

import axios from "axios";
import { API_URL } from "../config";

const deleteObjektsAPI = async ids => {
	try {
		const response = await axios.post(
			`${API_URL}/delete_objekts.php`,
			{ ids },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		// Возвращаем данные из ответа сервера
		return response.data;
	} catch (error) {
		console.error("Ошибка при удалении объектов:", error);
		throw error; // Пробрасываем ошибку дальше, чтобы её можно было обработать в вызывающем коде
	}
};

export default deleteObjektsAPI;

import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import Scheduling from "./scenes/scheduling";
import AddObjekt from "./scenes/addObjekt";
import DeleteObjekt from "./scenes/deleteObjekt";

import Pie from "./scenes/pie";
import { PageTitleProvider } from "./PageTitleContext";
import checkWeek from "./api/checkWeek";

function App() {
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);

	useEffect(() => {
		checkWeek();
	}, []);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<PageTitleProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<div className="app">
						<Sidebar isSidebar={isSidebar} />
						<main className="content">
							<Topbar setIsSidebar={setIsSidebar} />
							<Routes>
								<Route path="/" element={<Scheduling />} />
								<Route path="/addObjekt" element={<AddObjekt />} />
								<Route path="/deleteObjekt" element={<DeleteObjekt />} />
								<Route path="/deleteLS" element={<Pie />} />
								{/* <Route path="/team" element={<Team />} />
							<Route path="/contacts" element={<Contacts />} />
							<Route path="/invoices" element={<Invoices />} />
							<Route path="/form" element={<Form />} />
							<Route path="/bar" element={<Bar />} />
							<Route path="/pie" element={<Pie />} />
							<Route path="/line" element={<Line />} />
							<Route path="/faq" element={<FAQ />} />
							<Route path="/calendar" element={<Calendar />} />
							<Route path="/geography" element={<Geography />} /> */}
							</Routes>
						</main>
					</div>
				</ThemeProvider>
			</PageTitleProvider>
		</ColorModeContext.Provider>
	);
}

export default App;

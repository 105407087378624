import React, { useEffect, useState } from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	Button,
	Snackbar,
	Alert,
	CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "swiper/css";
import { API_URL } from "../../config";
import NoFoundText from "../UI/NoFoundText";

const AddresseModal = ({
	modalopen: open,
	handleCloseModal: handleClose,

	modalTitle,
	modalText,
	files,
	setFiles,
}) => {
	const [filesLoaded, setFilesLoaded] = useState(false);

	// Функция для получения списка файлов
	const fetchFiles = async (search = "") => {
		setFilesLoaded(false);

		try {
			const response = await fetch(
				`${API_URL}/get_pdf_addresse.php?search=${encodeURIComponent(search)}`
			);
			const data = await response.json();
			setFiles(data);
			setFilesLoaded(true);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		if (open) {
			fetchFiles(modalTitle);
		}
	}, [open]);

	const [openAlert, setOpenAlert] = useState(false);

	const handleCopy = text => {
		console.log(text);

		navigator.clipboard.writeText(text).then(() => {
			// Успешно скопировано
			setOpenAlert(true);
		});
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	function splitPath(path) {
		// Teilen des Pfades bei jedem Backslash
		const parts = path.split("\\");

		// Sicherstellen, dass mindestens drei Teile vorhanden sind
		if (parts.length < 3) {
			throw new Error("Der Pfad enthält nicht genügend Teile zum Aufteilen.");
		}

		// Die letzte und vorletzte Teile
		const lastPart = parts.pop(); // Entfernt und gibt das letzte Element zurück (die Datei)
		const secondLastPart = parts.pop(); // Entfernt und gibt das vorletzte Element zurück (der Name des letzten Ordners)

		// Alles davor zusammenfassen
		const firstPart = parts.join("\\");

		return [firstPart, secondLastPart, lastPart];
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			closeAfterTransition
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: { xs: "90%", sm: "70%", md: "50%" },
					height: { xs: "90%", sm: "85%", md: "80%" },
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: 2,
					p: 4,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					outline: "none",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: 8,
						right: 8,
					}}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
				<Typography id="modal-title" variant="h5" component="h2" align="center">
					{modalTitle}
				</Typography>
				<Typography id="modal-description" sx={{ mt: 2, mb: 2, fontSize: 20 }}>
					Schlüßelhaken: &nbsp; &nbsp;{modalText.keyObjekte}
					<br />
					Rhytmus: &nbsp; &nbsp;{modalText.rhythm}
					<br />
					Touren: &nbsp; &nbsp;{modalText.team}
				</Typography>
				{filesLoaded ? (
					files.length && filesLoaded ? (
						<ul className="adresse-files">
							<div className="adresse-files__title">
								Überprüfen Sie, ob das Angebot die richtige Adresse hat!
							</div>
							{files.map((file, index) => {
								const fileName = file.file_name.substring(
									file.file_name.lastIndexOf("/") + 1
								);
								const directoryName = file.directory.slice(2);

								const link = `Z:\\Angebote\\Angebote Alt oder Offen\\${directoryName}\\${fileName}`;
								const linkSplit = splitPath(link);

								return (
									<li className="adresse-files__item" key={index}>
										<Snackbar
											open={openAlert}
											autoHideDuration={3000}
											onClose={handleCloseAlert}
										>
											<Alert onClose={handleCloseAlert} severity="success">
												Die Link wurde kopiert. Fügen Sie sie selber ein.
											</Alert>
										</Snackbar>
										<span onClick={() => handleCopy(link)}>
											<span
												style={{
													color: "green",
													fontSize: "17px",
													textDecoration: "none",
												}}
											>
												{linkSplit[1]}
											</span>
											: <br />
											<span style={{ textDecoration: "underline" }}>
												{linkSplit[0]}\
												<span style={{ color: "green" }}>{linkSplit[1]}</span>\
												{linkSplit[2]}
											</span>
										</span>
									</li>
								);
							})}
						</ul>
					) : (
						<Box className="adresse-files-no-found">
							<NoFoundText variant="h4">
								Keine Files gefunden &nbsp; <RemoveCircleOutlineIcon />
							</NoFoundText>
							<Snackbar
								open={openAlert}
								autoHideDuration={3000}
								onClose={handleCloseAlert}
							>
								<Alert onClose={handleCloseAlert} severity="success">
									Die Link wurde kopiert. Fügen Sie sie selber ein.
								</Alert>
							</Snackbar>
							<a
								href="#"
								onClick={() =>
									handleCopy(`Z:\\Angebote\\Angebote Alt oder Offen`)
								}
							>
								Schauen Sie selber nach dem File
							</a>
							Z:\Angebote\Angebote Alt oder Offen
						</Box>
					)
				) : (
					<CircularProgress color="secondary" />
				)}
				<Button variant="contained" color="error" onClick={handleClose}>
					Schließen
				</Button>
			</Box>
		</Modal>
	);
};

export default AddresseModal;

import { useContext, useEffect, useState } from "react";

import Split from "react-split";

import ErrorAddresse from "./Error/ErrorAddresse.jsx";

import LeftSide from "./LeftSide.jsx";
import RightSide from "./RightSide.jsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import getFBWeekNumber from "../../utils/getFBWeekNumber.js";
import sortDataById from "../../utils/sortDataById.js";
import getLS from "../../utils/getLS.js";
import useGetUnassignmentsAPI from "../../api/useGetUnassignmentsAPI.js";
import useGetAssignedAddressesAPI from "../../api/useGetAssignedAddressesAPI.js";

import useAssignmentApi from "../../api/useAssignmentApi.js";
import { PageTitleContext } from "../../PageTitleContext.js";
import sortDataByCity from "../../utils/sortDataByCity.js";
import AddresseModal from "../../components/AddresseModal/AddresseModal.jsx";
import convertTeam from "../../utils/translate/translateTeam.js";

const Scheduling = () => {
	const [sizes, setSizes] = useState(() => {
		const savedSizes = getLS("splitPaneSizes");
		return savedSizes || [50, 50];
	});
	const [weekday, setWeekday] = useState("Monday");
	const [team, setTeam] = useState("Team1");
	const [currentDate, setCurrentDate] = useState(new Date());
	const [weekFB, setWeekFB] = useState(getFBWeekNumber());

	const [leftItems, setLeftItems] = useState([]);
	const [rightItems, setRightItems] = useState([]);

	const { data, isLoaded, error } = useGetUnassignmentsAPI(weekFB);
	const {
		data: data2,
		isLoaded: isLoaded2,
		error: error2,
	} = useGetAssignedAddressesAPI(weekFB, weekday, team);

	const [files, setFiles] = useState([]);

	const [modalopen, setModalOpen] = useState(false);
	const [modalTitle, setModaTitle] = useState("");
	const [modalText, setModalText] = useState({});

	const handleOpenModal = (keyObjekte, rhythm, team, text) => {
		setModalOpen(true);
		setModaTitle(text);

		setModalText({
			keyObjekte: keyObjekte ? keyObjekte : "-",
			rhythm: rhythm ? rhythm : "-",
			team: team ? convertTeam(team) : "-",
		});
	};

	const handleCloseModal = () => {
		setFiles([]);
		setModalOpen(false);
	};

	const {
		addToAssignment,
		removeFromAssigment,
		isLoadedAssignment,
		errorAssignment,
	} = useAssignmentApi();

	useEffect(() => {
		if (isLoaded) {
			if (!error) {
				setLeftItems(sortDataByCity(data));
			} else {
				setLeftItems([]);
			}
		}
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded2) {
			if (!error2) {
				if (team === "Alle Touren") {
					// Покапзываем все объекты таким способом потому что на сервере нет поля для всех комманд
					const combinedTasks = Object.values(data2[weekday]).flat();
					setRightItems(sortDataByCity(combinedTasks));
				} else {
					setRightItems(sortDataByCity(data2[weekday][team]));
				}
			} else {
				setRightItems([]);
			}
		}
	}, [isLoaded2]);

	useEffect(() => {
		setWeekFB(getFBWeekNumber(currentDate));
	}, [currentDate]);

	useEffect(() => {
		localStorage.setItem("splitPaneSizes", JSON.stringify(sizes));
	}, [sizes]);

	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Verplanung der Reinigung");
	}, [setTitle]);

	const handleDragEnd = newSizes => {
		setSizes(newSizes);
	};

	// Функция которая отвечает за пермещение элменетов с обоих сторон и замену состояния.
	const handleDrop = (item, target) => {
		let sourceItems, setSourceItems, targetItems, setTargetItems;

		if (team === "Alle Touren") return false;

		if (target === "left") {
			sourceItems = rightItems;
			setSourceItems = setRightItems;
			targetItems = leftItems;
			setTargetItems = setLeftItems;
		} else {
			sourceItems = leftItems;
			setSourceItems = setLeftItems;
			targetItems = rightItems;
			setTargetItems = setRightItems;
		}

		const droppedItem = sourceItems.find(i => i.id === item.id);
		if (!droppedItem) return; // Check if dropped item exists to prevent undefined error

		const newSourceItems = sourceItems.filter(i => i.id !== item.id);
		const newTargetItems = [...targetItems, droppedItem];

		const sortedSourcesItems = sortDataByCity(newSourceItems);
		const sortedTargetItems = sortDataByCity(newTargetItems);

		setSourceItems(sortedSourcesItems);
		setTargetItems(sortedTargetItems);

		// Вызов функции отправки данных на сервер
		sendDataToServer(droppedItem, target);
	};

	// Функция для отправки данных на сервер
	const sendDataToServer = (item, target) => {
		if (target === "right") {
			// Добавить адрес в назначеный
			addToAssignment(item, weekFB, weekday, team);
		}
		if (target === "left") {
			// Убрать адрес из назначеного
			removeFromAssigment(item, weekFB, weekday, team);
		}
	};

	const setTeamHandle = value => {
		setTeam(value);
		setRightItems([]);
	};

	const setWeekdayHandle = value => {
		setWeekday(value);
		setRightItems([]);
	};

	return (
		<section className="scheduling">
			<AddresseModal
				modalopen={modalopen}
				handleCloseModal={handleCloseModal}
				modalTitle={modalTitle}
				modalText={modalText}
				files={files}
				setFiles={setFiles}
			/>
			<div className="scheduling__content">
				<div className="split-container scheduling__split-container">
					<Split
						sizes={sizes} // Размеры панелей в процентах (по умолчанию 50% каждая)
						minSize={500} // Минимальный размер каждой панели в пикселях
						expandToMin={false}
						gutterSize={6} // Размер линии перетаскивания
						gutterAlign="center"
						snapOffset={30}
						dragInterval={15}
						direction="horizontal" // Горизонтальное деление
						cursor="col-resize"
						className="split"
						onDragEnd={handleDragEnd}
					>
						<DndProvider backend={HTML5Backend}>
							<LeftSide
								error={error}
								isLoaded={isLoaded}
								currentDate={currentDate}
								setCurrentDate={setCurrentDate}
								setLeftItems={setLeftItems}
								setRightItems={setRightItems}
								leftItems={leftItems}
								handleDrop={handleDrop}
								team={team}
								setTeam={setTeam}
								handleOpenModal={handleOpenModal}
							/>

							<div className="panel scheduling__split-panel scheduling__weekdays">
								{error ? (
									<ErrorAddresse
										sx={{ paddingBottom: 0, marginTop: 7 }}
										err={error}
									/>
								) : (
									<RightSide
										weekday={weekday}
										weekFB={weekFB}
										setWeekday={setWeekdayHandle}
										rightItems={rightItems}
										handleDrop={handleDrop}
										team={team}
										setTeam={setTeamHandle}
										isLoaded2={isLoaded2}
										isLoadedAssignment={isLoadedAssignment}
										assignmentError={errorAssignment}
										handleOpenModal={handleOpenModal}
									/>
								)}
							</div>
						</DndProvider>
					</Split>
				</div>
			</div>
		</section>
	);
};

export default Scheduling;
